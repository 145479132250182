// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Link } from "@material-ui/core";
import { mdiCalculator, mdiCancel, mdiDelete, mdiPlus } from "@mdi/js";
import { Button, Modal, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  getCategoryList,
  setIsUpdateGeoCalculationJobModalOpen,
  setSelectedGeoCalculationJob
} from "store/features";
import styled from "styled-components";

import { Heading } from "../base";
import { useCalculateGeoModel } from "./hooks/useCalculateGeoModel";
import { useCancelGeoCalculationJob } from "./hooks/useCancelGeoCalculationJob";
import { useDeleteGeoCalculationJob } from "./hooks/useDeleteGeoCalculationJob";
import { useGetGeoCalculationsJobs } from "./hooks/useGetGeoCalculationsJobs";
import { useJobStatus } from "./hooks/useJobStatus";
import { UpdateGeoCalculationJobModal } from "./modals";
import { CreateGeoCalculationJobModal } from "./modals/CreateGeoCalculationJobModal";
import { GeoCalculationsJob } from "./models/GeoCalculations";

export function OrganizationGeoCalculationJobs() {
  const dispatch = useDispatch();

  const getGeoCalculationJobs = useGetGeoCalculationsJobs();
  const deleteGeoCalculationJob = useDeleteGeoCalculationJob();
  const cancelGeoCalculationJob = useCancelGeoCalculationJob();
  const calculateGeoModel = useCalculateGeoModel();

  const [jobStatus, setJobStatus] = useState<{ [key: string]: string }>({});
  const [showModal, setShowModal] = useState(false);

  useJobStatus(getGeoCalculationJobs.data, setJobStatus);

  const formattedJobs: GeoCalculationsJob[] = useMemo(
    () =>
      getGeoCalculationJobs.data?.map((job) => {
        const date = job?.lastSuccessfulRunDate?.$date?.$numberLong;
        const newDate = date
          ? new Date(
              parseInt(job.lastSuccessfulRunDate.$date.$numberLong)
            ).toLocaleString()
          : null;

        const newJob = {
          ...job,
          modelSource: job.modelSource || "McDaniel",
          formattedLastSuccessfulRunDate: newDate
        };

        return newJob;
      }),
    [getGeoCalculationJobs.data]
  );

  function isActive(record: GeoCalculationsJob) {
    return (
      jobStatus[record.jobId] &&
      jobStatus[record.jobId] != "Completed" &&
      jobStatus[record.jobId] != "Not started" &&
      jobStatus[record.jobId] != "Error"
    );
  }

  const columns: ColumnsType<GeoCalculationsJob> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Left Width",
      dataIndex: "leftWidth",
      key: "leftWidth"
    },
    {
      title: "Right Width",
      dataIndex: "rightWidth",
      key: "rightWidth"
    },
    {
      title: "Up Height",
      dataIndex: "upHeight",
      key: "upHeight"
    },
    {
      title: "Down Height",
      dataIndex: "downHeight",
      key: "downHeight"
    },
    {
      title: "Well List Count",
      dataIndex: "wellListCount",
      key: "wellListCount",
      render: (text, record) => {
        const wellListCount = record.wellList?.length || 0;

        return (
          <Link
            onClick={() => {
              dispatch(setIsUpdateGeoCalculationJobModalOpen(true));
              dispatch(setSelectedGeoCalculationJob(record));
            }}
            underline="always">
            {wellListCount}
          </Link>
        );
      }
    },
    {
      title: "Source",
      dataIndex: "modelSource",
      key: "modelSource"
    },
    {
      title: "Zones",
      dataIndex: "zones",
      key: "zones",
      render: (text, record) => {
        return record.zones?.join(", ");
      }
    },
    {
      title: "Last Successful Run",
      dataIndex: "formattedLastSuccessfulRunDate",
      key: "formattedLastSuccessfulRunDate"
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <ButtonWrapper>
          <Tooltip title="Schedule">
            <Button
              style={{ margin: "1px" }}
              icon={
                isActive(record) || jobStatus[record.jobId] == "Error" ? null : (
                  <Icon path={mdiCalculator} size={1.4} />
                )
              }
              loading={isActive(record) || calculateGeoModel.isLoading}
              type="primary"
              onClick={async () => {
                await calculate(record.jobId);
                toast.info(
                  "Job has been queued. You will receive an email when the job is completed."
                );
              }}>
              {isActive(record) || jobStatus[record.jobId] == "Error"
                ? jobStatus[record.jobId]
                : ""}
            </Button>
          </Tooltip>
          <Tooltip title="Cancel" overlayInnerStyle={{ padding: "10px" }}>
            <Button
              style={{ margin: "1px" }}
              type="primary"
              danger
              disabled={!isActive(record)}
              icon={<Icon path={mdiCancel} size={1.4} />}
              onClick={() => handleCancel(record.jobId)}
            />
          </Tooltip>
          <Tooltip title="Delete" overlayInnerStyle={{ padding: "10px" }}>
            <Button
              style={{ margin: "1px" }}
              type="primary"
              danger
              icon={<Icon path={mdiDelete} size={1.4} />}
              onClick={() => handleDelete(record.jobId)}
            />
          </Tooltip>
        </ButtonWrapper>
      )
    }
  ];

  async function calculate(id: string) {
    await calculateGeoModel
      .mutateAsync(id)
      .catch(() => {
        toast.error("An error occurred while calculating the calculation job.");
      })
      .then(() => {
        dispatch(getCategoryList());
      });
  }

  async function handleCancel(id) {
    Modal.confirm({
      title: "Confirm Cancel",
      content: "Cancel the calculation job?",
      onOk: async () => {
        const updatedJobs = await getGeoCalculationJobs.refetch();
        const jobToCancel = updatedJobs.data.find((job) => job.jobId === id);
        await cancelGeoCalculationJob
          .mutateAsync({ jobId: id, runId: jobToCancel.runId })
          .catch(() => {
            toast.error("An error occurred while cancelling the calculation job.");
          });
      }
    });
  }

  async function handleDelete(id) {
    Modal.confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this calculation job and all calculated data?",
      onOk: async () => {
        await deleteGeoCalculationJob
          .mutateAsync(id)
          .catch(() => {
            toast.error("An error occurred while deleting the calculation job.");
          })
          .then(() => {
            dispatch(getCategoryList());
          });
      }
    });
  }

  if (getGeoCalculationJobs.isError) {
    return <ErrorMessage>Error occurred while fetching tokens</ErrorMessage>;
  }

  return (
    <div>
      <Heading element="h4">Geo Model Calculations </Heading>
      <ButtonWrapper>
        <Button
          icon={<Icon path={mdiPlus} size={1} />}
          type="primary"
          onClick={() => {
            setShowModal(true);
          }}>
          Add New Calculation Job
        </Button>
      </ButtonWrapper>
      <Table dataSource={formattedJobs} columns={columns} rowKey="jobId" />

      <UpdateGeoCalculationJobModal />
      <CreateGeoCalculationJobModal
        requestShowModal={showModal}
        jobs={getGeoCalculationJobs.data}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}

const ErrorMessage = styled.div`
  color: red;
  font-size: 20px;
  margin: 20px;
  border: 1px solid red;
  padding: 10px;
  background-color: #ffe6e6;
  border-radius: 5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
