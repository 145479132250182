import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { NavigationBar } from "components/navigation";
import DocumentationDrawer from "components/user-documentation/DocumentationDrawer";

import { ErrorBoundary } from "../base";
import { DeveloperApiTokenManagement } from "./DeveloperApiTokenManagement";
import OrganizationBillingHistory from "./OrganizationBillingHistory";
import OrganizationDataSources from "./OrganizationDataSources";
import OrganizationDisplaySettings from "./OrganizationDisplaySettings";
import { OrganizationGeoCalculationJobs } from "./OrganizationGeoCalculationJobs";
import OrganizationMembers from "./OrganizationMembers";
import OrganizationProfile from "./OrganizationProfile";
import OrganizationSecurity from "./OrganizationSecurity";
import "./OrganizationSettingsLayout.scss";
import OrganizationSettingsSideNav from "./OrganizationSettingsSideNav";
import OrganizationSubscription from "./OrganizationSubscription";
import OrganizationShapefiles from "./org-shapefiles/OrganizationShapefiles";

export default function OrganizationSettingsLayout({ authenticated: isAuthenticated }) {
  const activeSetting = useSelector(
    (state: RootState) => state.userSetting.activeSetting
  );

  const components = {
    OrganizationProfile: <OrganizationProfile />,
    OrganizationSecurity: <OrganizationSecurity />,
    OrganizationShapefiles: <OrganizationShapefiles />,
    OrganizationDisplaySettings: <OrganizationDisplaySettings />,
    OrganizationSubscription: <OrganizationSubscription />,
    OrganizationMembers: <OrganizationMembers />,
    OrganizationBillingHistory: <OrganizationBillingHistory />,
    OrganizationDataSources: <OrganizationDataSources />,
    DeveloperApiTokenManagement: <DeveloperApiTokenManagement />,
    OrganizationGeoCalculationJobs: <OrganizationGeoCalculationJobs />
  };
  return (
    <div className="SettingsOverlay">
      <div className="OrganizationSettingsLayout">
        <NavigationBar showDashboardButtons={false} />
        <div className="settings-content">
          <div className="settings-side-bar">
            <OrganizationSettingsSideNav />
          </div>
          <div className="organizationAccount">
            <ErrorBoundary>
              {isAuthenticated ? components[activeSetting] : null}
            </ErrorBoundary>
          </div>
        </div>
        <DocumentationDrawer />
      </div>
    </div>
  );
}
