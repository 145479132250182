import { FC } from "react";
import { useSelector } from "react-redux";

import { Select, Tooltip } from "antd";
import { MCDANIEL_FOLDER_ID, NOVI_FOLDER_ID } from "constants/settings.constants";
import { ForecastModeT } from "store/features";
import { RootState } from "store/rootReducer";

type ForecastModeSelectT = {
  onChange: (v: ForecastModeT) => void;
  value: ForecastModeT;
};

const { Option } = Select;

const ForecastModeSelect: FC<ForecastModeSelectT> = ({ onChange, value }) => {
  const useMcDanielForecasts = useSelector(
    (state: RootState) => state.arps.useMcDanielForecasts
  );
  const useNoviForecasts = useSelector((state: RootState) => state.arps.useNoviForecasts);
  const checkedUserForecastKeys = useSelector((state: RootState) =>
    state.arps.checkedForecastKeys.filter(
      (key) => key !== MCDANIEL_FOLDER_ID && key !== NOVI_FOLDER_ID
    )
  );
  const compareModeDisabled =
    !(useMcDanielForecasts || useNoviForecasts) || checkedUserForecastKeys.length > 1;

  return (
    <Select style={{ width: "auto" }} value={value} onChange={onChange}>
      <Option value="forecastTrump">
        <Tooltip placement="right" title="Project forecasts trump McDaniel forecasts">
          Forecast Trumping
        </Tooltip>
      </Option>
      <Option value="forecastCompare" disabled={compareModeDisabled}>
        <Tooltip
          placement="right"
          title="Compare McDaniel forecasts with project forecasts. Project forecasts are shown from end of historical production. ">
          Forecast Compare
        </Tooltip>
      </Option>
      <Option value="historicalCompare" disabled={compareModeDisabled}>
        <Tooltip
          placement="right"
          title="Compare McDaniel forecasts with project forecasts. Project forecasts are shown from forecast start date">
          Historical Compare
        </Tooltip>
      </Option>
    </Select>
  );
};

export default ForecastModeSelect;
