import {
  SELECTED_SINGLE_WELL_LAYER,
  SELECTED_WELL_LAYER,
  SELECTED_WELL_POINT_LAYER,
  TYPE_LOG_LAYER,
  TYPE_LOG_VERTICAL_LAYER,
  WELL_LAYER,
  WELL_LAYER_POINT
} from "constants/mapLayers.constants";

import { IProjectLayer } from "components/project/layers/queries";

import {
  IEditableProjectLayer,
  LayerListFilterTypesT
} from "../../useEditableProjectLayerList";

export interface ICreateEditableLayerListParams {
  layerList: IProjectLayer[];
  checkedKeys: string[];
  filters?: LayerListFilterTypesT[];
}

interface ICreateEditableLayerListReturn {
  list: IEditableProjectLayer[];
  checkedShapefiles: Set<string>;
  checkedProjectShapefileIds: string[];
  checkedProjectLinkedShapefileIds: string[];
}

// TODO: add dependant layers to the list

export function createEditableLayerList(
  params: ICreateEditableLayerListParams
): ICreateEditableLayerListReturn {
  const checkedProjectShapefileIds = [];
  const checkedProjectLinkedShapefileIds = [];
  const checkedShapefiles = new Set<string>();

  const newList: IEditableProjectLayer[] = [];

  params.layerList?.forEach((layer: IProjectLayer) => {
    const layerName = layer.projectShapefileId || layer.shapefileId || layer.name;
    const isChecked = params.checkedKeys.includes(layerName);

    if (params.checkedKeys.includes(layer.projectShapefileId)) {
      checkedProjectLinkedShapefileIds.push(layer.projectShapefileId);
      checkedShapefiles.add(layer.projectShapefileId);
    }

    if (params.checkedKeys.includes(layer.shapefileId)) {
      checkedProjectShapefileIds.push(layer.shapefileId);
      checkedShapefiles.add(layer.shapefileId);
    }

    addDependantMapLayers({ newList, isChecked, layer, layerName });

    newList.push({
      ...layer,
      isChecked: isChecked,
      isMoving: false,
      zoomLevel: 8
    });
  });

  const filteredList = newList.filter((layer) => {
    if (!params.filters?.length) return true;

    const isHidingUnselectedLayer =
      params.filters.includes("unselectedLayers") && !layer.isChecked;

    const isDependantLayer =
      params.filters.includes("dependantLayers") && layer.dependantLayer;

    // If unselectedLayers filter is applied and layer is unchecked, filter it out
    if (isHidingUnselectedLayer) {
      return false;
    }

    // If dependantLayers filter is applied and layer has a dependent layer, filter it out
    if (isDependantLayer) {
      return false;
    }

    // Otherwise, include the layer
    return true;
  });

  const orderedList = filteredList.map((layer, index) => ({ ...layer, order: index }));

  return {
    list: orderedList,
    checkedProjectShapefileIds,
    checkedProjectLinkedShapefileIds,
    checkedShapefiles
  };
}

function addDependantMapLayers(params: {
  isChecked: boolean;
  layer: IProjectLayer;
  layerName: string;
  newList: IEditableProjectLayer[];
}) {
  const { layer, layerName, newList, isChecked } = params;

  if ([SELECTED_WELL_LAYER, WELL_LAYER].includes(layerName)) {
    if (layerName === SELECTED_WELL_LAYER) {
      newList.push({
        ...layer,
        dependantLayer: SELECTED_WELL_LAYER,
        isChecked,
        isMoving: false,
        layerId: SELECTED_WELL_POINT_LAYER,
        name: SELECTED_WELL_POINT_LAYER,
        order: undefined,
        zoomLevel: 8
      });

      newList.push({
        ...layer,
        dependantLayer: SELECTED_WELL_LAYER,
        isChecked,
        isMoving: false,
        layerId: SELECTED_SINGLE_WELL_LAYER,
        name: SELECTED_SINGLE_WELL_LAYER,
        order: undefined,
        zoomLevel: 8
      });
    }

    if (layerName === WELL_LAYER) {
      newList.push({
        ...layer,
        dependantLayer: WELL_LAYER,
        isChecked,
        isMoving: false,
        layerId: WELL_LAYER_POINT,
        name: WELL_LAYER_POINT,
        order: undefined,
        zoomLevel: 8
      });
    }

    if (layerName === TYPE_LOG_LAYER) {
      newList.push({
        ...layer,
        dependantLayer: TYPE_LOG_LAYER,
        isChecked,
        isMoving: false,
        layerId: TYPE_LOG_VERTICAL_LAYER,
        name: TYPE_LOG_VERTICAL_LAYER,
        order: undefined,
        zoomLevel: 8
      });
    }
  }
}
