import { useQuery } from "react-query";

import axios from "axios";

import { ApiResult } from "./apiResult";
import { ITypeWellProductSettings, ITypeWellSettings } from "./typeWell";

export const defaultTypeWellProductSettings: ITypeWellProductSettings[] = [
  {
    product: "GAS",
    headers: ["Q0", "tramp", "Qi", "Di", "Btrans", "ttrans", "Bhyp", "Df", "Bf", "Qf"],
    displayHeaders: [
      "Q.0",
      "t.ramp",
      "Q.i",
      "D.i",
      "B.trans",
      "t.trans",
      "B.hyp",
      "D.f",
      "B.f",
      "Q.f"
    ],
    values: {
      Q0: 0,
      tramp: 2,
      Qi: 5000,
      Di: 1.203972804325936,
      Btrans: 1.2,
      ttrans: 12,
      Bhyp: 1,
      Df: 0.08338160893905101,
      Bf: 0,
      Qf: 50
    }
  },
  {
    product: "OIL",
    headers: ["Q0", "tramp", "Qi", "Di", "Btrans", "ttrans", "Bhyp", "Df", "Bf", "Qf"],
    displayHeaders: [
      "Q.0",
      "t.ramp",
      "Q.i",
      "D.i",
      "B.trans",
      "t.trans",
      "B.hyp",
      "D.f",
      "B.f",
      "Q.f"
    ],
    values: {
      Q0: 0,
      tramp: 2,
      Qi: 250,
      Di: 1.203972804325936,
      Btrans: 1.2,
      ttrans: 12,
      Bhyp: 1,
      Df: 0.08338160893905101,
      Bf: 0,
      Qf: 5
    }
  },
  {
    product: "CONDENSATE",
    headers: ["Q0", "tramp", "Qi", "Di", "Btrans", "ttrans", "Bhyp", "Df", "Bf", "Qf"],
    displayHeaders: [
      "Q.0",
      "t.ramp",
      "Q.i",
      "D.i",
      "B.trans",
      "t.trans",
      "B.hyp",
      "D.f",
      "B.f",
      "Q.f"
    ],
    values: {
      Q0: 0,
      tramp: 2,
      Qi: 250,
      Di: 1.203972804325936,
      Btrans: 1.2,
      ttrans: 12,
      Bhyp: 1,
      Df: 0.08338160893905101,
      Bf: 0,
      Qf: 5
    }
  },
  {
    product: "WATER",
    headers: ["Q0", "tramp", "Qi", "Di", "Btrans", "ttrans", "Bhyp", "Df", "Bf", "Qf"],
    displayHeaders: [
      "Q.0",
      "t.ramp",
      "Q.i",
      "D.i",
      "B.trans",
      "t.trans",
      "B.hyp",
      "D.f",
      "B.f",
      "Q.f"
    ],
    values: {
      Q0: 0,
      tramp: 2,
      Qi: 250,
      Di: 1.203972804325936,
      Btrans: 1.2,
      ttrans: 12,
      Bhyp: 1,
      Df: 0.08338160893905101,
      Bf: 0,
      Qf: 5
    }
  }
];

///hook to fetch default type wells
const userRoot = process.env.REACT_APP_USER_SERVICE;
export default function useTypeWellSettings(): ApiResult<ITypeWellSettings> {
  const { data, isLoading, isError, refetch } = useQuery<ITypeWellSettings>(
    "fetchDefaultTypeWells",
    async () => {
      try {
        const response = await axios.get(`${userRoot}/user/setting/DefaultTypeWell/`);

        if (
          response.status === 200 &&
          response?.data?.length &&
          response.data[0].settings?.length
        ) {
          return response.data[0];
        } else {
          return {
            id: "DefaultTypeWell",
            settings: defaultTypeWellProductSettings
          } as ITypeWellSettings;
        }
      } catch (err) {
        return {
          id: "DefaultTypeWell",
          settings: defaultTypeWellProductSettings
        } as ITypeWellSettings;
      }
    },
    {
      enabled: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (axios.isCancel(error)) {
          return;
        }
      }
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch
  };
}
