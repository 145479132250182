import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  GEO_MAP_LAYER,
  IPDB_CONTROL_WELLS_LAYER,
  IPDB_LAYER,
  WELL_LAYER_POINT
} from "constants/mapLayers.constants";
import { RootState } from "store/rootReducer";

import { useMapContext } from "../useMapContext";

interface IUseManageMapboxLayerStylesParams {
  mapbox: mapboxgl.Map;
}

/**
 * Visual changes to the mapbox layers are applied here based on the redux map layers. (visibility, zoom, etc)
 *
 * Example use cases:
 * Primarily to update properties on mapbox that aren't stored in the backend, the shapefiles are for the most part updated in the shapefile loader.
 *
 * Visibility is updated on layer tree/list check handler, and in project initialization (useProjectInitialization hook).
 * Both cases utilize the useUpdateMapLayersVisibility hook to set the redux map layers.
 *
 * Zoom is handled with the layer tree/list ZoomSlider component
 * @param param0
 */
export function useManageMapboxLayerStyles({
  mapbox
}: IUseManageMapboxLayerStylesParams) {
  const mapboxLayers = useSelector((state: RootState) => state.map.layers);

  const { isStyleLoaded } = useMapContext();

  useEffect(() => {
    if (!mapbox || !isStyleLoaded) {
      return;
    }

    for (const layer of mapboxLayers) {
      if (
        layer.id === IPDB_LAYER ||
        layer.id === GEO_MAP_LAYER ||
        layer.id === IPDB_CONTROL_WELLS_LAYER
      ) {
        continue;
      }

      // Shapefiles can be removed from the map, but the layer will still exist in the mapboxLayers
      if (mapbox.getLayer(layer.id)) {
        const isLayerVisible = layer.isVisible ? "visible" : "none";
        mapbox.setLayoutProperty(layer.id, "visibility", isLayerVisible);

        if (layer.minzoom || layer.maxzoom) {
          mapbox.setLayerZoomRange(layer.id, layer.minzoom, layer.maxzoom);
        }

        if (layer.lineWidth > 0) {
          mapbox.setPaintProperty(layer.id, "line-width", layer.lineWidth);
        }

        if (layer.circleRadius > 0) {
          const radius = [
            "interpolate",
            ["linear"],
            ["zoom"],
            6,
            2,
            15,
            4 + layer.circleRadius * 2,
            22,
            8 + layer.circleRadius * 4
          ];
          mapbox.setPaintProperty(WELL_LAYER_POINT, "circle-radius", radius);
        }
      }
    }
  }, [mapbox, mapboxLayers, isStyleLoaded]);
}
