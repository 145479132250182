import { useDispatch, useSelector } from "react-redux";

import { setLayers } from "store/features";
import { RootState } from "store/rootReducer";

interface IUpdateLayerLineThicknessParams {
  layerName: string;
  thickness: number;
}

export function useUpdateLayerLineThickness() {
  const dispatch = useDispatch();
  const mapLayers = useSelector((state: RootState) => state.map.layers);

  const updateLayerLineThickness = ({
    layerName,
    thickness
  }: IUpdateLayerLineThicknessParams) => {
    const updatedMapLayers = mapLayers.map((layer) => {
      if (layer.id === layerName)
        //not correct
        return { ...layer, lineWidth: thickness };
      return { ...layer };
    });

    dispatch(setLayers(updatedMapLayers));
  };

  return { updateLayerLineThickness };
}
