import { Button } from "antd";
import styled from "styled-components";

export const BusyIndicator = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hidden {
    display: none;
  }
`;

export const ErrorOverlay = styled.div`
  position: absolute;
  left: 2px;
  top: 2px;
  right: 0;
  bottom: 0;
  background: #a8a8a811;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 997;
`;

export const BusyOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const UserArpsContainer = styled.div`
  grid-template-columns: minmax(0, 1fr);
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  height: 100%;
  overflow-y: hidden;

  .sub-heading {
    flex-direction: row;
    display: flex;
    padding: 5px 20px;
  }

  .ant-collapse-header {
    overflow: hidden;
  }

  .ant-tabs-content-holder {
    display: grid;
    grid-template-rows: minmax(0, 1fr);
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const ButtonIconCentered = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-action-button);
  &:hover {
    color: var(--color-primary);
  }
`;
