import { useMemo } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";

import { getWellsFromFilterId } from "api/filter";

import useFourProductRateTimeQuery from "./useFourProductRateTimeQuery";
import useTypeWellQuery from "./useTypeWellQuery";
import useWaterBalanceQuery from "./useWaterBalanceQuery";

export function useSamChartsMaxXValue() {
  const filterId = useSelector((state: RootState) => state.filter.filterId);

  const { data: wellList } = useQuery("well-list-query", async () => {
    const response = await getWellsFromFilterId(filterId);
    if (response.status === 200) {
      return response.data.uwiList?.map((w) => w.formattedId).sort();
    }
    return [];
  });

  const { xAxisMaxValue: fourProductXAxisMaxValue } =
    useFourProductRateTimeQuery(wellList);
  const { xAxisMaxValue: waterBalanceXAxisMaxValue } = useWaterBalanceQuery(wellList);
  const { xAxisMaxValue: typeWellXAxisMaxValue } = useTypeWellQuery(wellList);

  const xAxisMaxValue = useMemo(() => {
    return Math.max(
      fourProductXAxisMaxValue,
      waterBalanceXAxisMaxValue,
      typeWellXAxisMaxValue
    );
  }, [fourProductXAxisMaxValue, waterBalanceXAxisMaxValue, typeWellXAxisMaxValue]);
  return { xAxisMaxValue };
}
