import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { useUser } from "hooks";

import {
  ORGANIZATION_SHAPEFILES_QUERY_KEYS,
  PROJECT_SHAPEFILES_QUERY_KEYS
} from "components/project/shapefiles/queries";

export interface IUseCreateProjectShapefileFolderMutation {
  projectId: string;
  onMutate?: () => void;
  onSuccess?: (data) => void;
  onError?: (error) => void;
  onSettled?: () => void;
}

export type CreateProjectFolderParamsT = {
  parentId: string | null;
  order: number;
};

export async function createProjectShapefileFolder(params) {
  const { projectId, parentId, userId, order } = params;
  const url = `${process.env.REACT_APP_PROJECT_SERVICE}/shapefile-node/${
    parentId == null ? "" : parentId
  }`;
  const body = {
    projectId: projectId === "organization" ? "" : projectId,
    parentId: parentId ?? "",
    owner: userId.toString(),
    name: "New Folder",
    order: order
  };
  return await axios.post(url, body);
}

export function useCreateProjectShapefileFolderMutation({
  projectId,
  onMutate,
  onSuccess,
  onError,
  onSettled
}: IUseCreateProjectShapefileFolderMutation) {
  const queryClient = useQueryClient();
  const { user } = useUser();

  return useMutation(
    (params: CreateProjectFolderParamsT) =>
      createProjectShapefileFolder({ ...params, projectId, userId: user?.id ?? "" }),
    {
      onMutate: onMutate && onMutate,
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles
        });

        if (projectId === "organization") {
          await queryClient.invalidateQueries({
            queryKey: [ORGANIZATION_SHAPEFILES_QUERY_KEYS.organizationShapefiles]
          });
        }

        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
