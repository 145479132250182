import { useEffect, useState } from "react";

import { Select, Slider } from "antd";
import { RADIUS_DIMENSION_MAX } from "constants/visualization.constants";
import styled from "styled-components/macro";

import getGeoMapLayers from "components/geo-map/hooks/getGeoMapLayers";
import { useUserSettings } from "components/user/hooks";

import { updateXDASettings, useVisState } from "../../context";
import { XDASettingsT } from "../../context/types";

const XdaSettingsOptions = () => {
  const [{ xda }, visDispatch] = useVisState();
  const { topsModelSource, widthScaled } = xda.settings;
  const userSettings = useUserSettings();

  const { data: mapLayers } = getGeoMapLayers();

  const mcdanielGroup = [
    {
      label: "McDaniel",
      options: [{ label: "McDaniel", value: "McDaniel" }]
    }
  ];
  const [topsModelSources, setTopsModelSources] = useState(mcdanielGroup);

  useEffect(() => {
    if (!userSettings?.geoModelSettings?.organizationEnabled) return;
    const orgGroups = {
      label: "Organization",
      options: []
    };
    if (mapLayers?.length > 0) {
      for (const item of mapLayers) {
        if (orgGroups.options.findIndex((o) => o.value === item.group) === -1) {
          orgGroups.options.push({ label: item.group, value: item.group });
        }
      }
    }
    setTopsModelSources([...mcdanielGroup, orgGroups]);
  }, [mapLayers, userSettings?.geoModelSettings?.organizationEnabled]);

  // sync option changes with context
  const handleSettingChange = (key: keyof XDASettingsT) => (value) => {
    let updatedValue = value;
    if (key === "scaleByValue") {
      //Convert value to 32-bit unsigned integer if handlesSettingChange is wrapped in inputWrapper. Math.min to ensure does not go above max if input is inputted via typing
      updatedValue = Math.min(RADIUS_DIMENSION_MAX, value * 1);
    }
    const nextSettings = { ...xda.settings, [key]: updatedValue };
    updateXDASettings(visDispatch, nextSettings);
  };

  return (
    <Options>
      <OptionItem>
        <Label>Scale Width</Label>
        <Slider
          min={1}
          max={10}
          value={widthScaled}
          onChange={handleSettingChange("widthScaled")}
        />
      </OptionItem>
      <OptionItem>
        <Label>Tops Model Source</Label>
        <Select
          mode="multiple"
          size="small"
          defaultValue={["McDaniel"]}
          value={topsModelSource}
          options={topsModelSources}
          onChange={handleSettingChange("topsModelSource")}
        />
      </OptionItem>
    </Options>
  );
};

export default XdaSettingsOptions;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  width: 400px;

  .ant-card-body {
    padding: 5px;
    padding-right: 10px;

    height: 100%;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Label = styled.label`
  min-width: 120px;
`;

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;

  .ant-slider {
    width: 80px;
  }

  .ant-input-number {
    max-width: 130px;
    width: 130px;
  }

  .ant-select {
    width: 130px;
  }

  .ant-btn {
    border-radius: 5px;
  }

  .ant-btn-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-btn:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }

  padding: 2px 0;
`;
