// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiCog } from "@mdi/js";
import { Button, Popover, Tooltip } from "antd";

import {
  useUpdateLayerCircleRadius,
  useUpdateLayerLineThickness
} from "components/project/layers/hooks";

import {
  IZoomSliderProps,
  LayerSettingsRowItem,
  LayerSettingsWrapper,
  RightInputNumber,
  StyledDivider,
  ZoomSlider
} from "..";

interface MapLayerSettingsProps {
  dotThicknessSettings?: {
    min?: number;
    max?: number;
    value: number;
  };
  lineThicknessSettings?: {
    min?: number;
    max?: number;
    value: number;
  };
  layerName: string;
  zoomSliderProps: IZoomSliderProps;
}

export const MapLayerSettings = ({
  dotThicknessSettings,
  layerName = null,
  lineThicknessSettings,
  zoomSliderProps
}: MapLayerSettingsProps): JSX.Element => {
  const { updateLayerCircleRadius } = useUpdateLayerCircleRadius();
  const { updateLayerLineThickness } = useUpdateLayerLineThickness();

  const [lineThickness, setLineThickness] = useState(lineThicknessSettings?.value || 2);
  const [dotThickness, setDotThickness] = useState(dotThicknessSettings?.value || 2);

  const layerSettings = (
    <LayerSettingsWrapper direction="vertical" split={<StyledDivider />}>
      <LayerSettingsRowItem isFirst isSlider>
        <ZoomSlider
          defaultMin={zoomSliderProps.defaultMin}
          defaultMax={zoomSliderProps.defaultMax}
          layerName={zoomSliderProps.layerName}
          isShapefileLayer={zoomSliderProps.isShapefileLayer}
        />
      </LayerSettingsRowItem>
      {lineThicknessSettings && (
        <LayerSettingsRowItem>
          <label>Line Thickness</label>
          <RightInputNumber
            data-testid="layerThickness"
            type="number"
            min={1}
            max={10}
            value={lineThickness}
            onChange={(val) => {
              const nextThickness =
                parseFloat(val.target.value) > 10 ? 10 : parseFloat(val.target.value);

              setLineThickness(nextThickness);
              updateLayerLineThickness({
                layerName: layerName,
                thickness: nextThickness
              });
            }}
          />
        </LayerSettingsRowItem>
      )}

      {dotThicknessSettings && (
        <LayerSettingsRowItem isLast>
          <label>Dot Thickness</label>
          <RightInputNumber
            data-testid="dotThickness"
            type="number"
            min={1}
            max={10}
            value={dotThickness}
            onChange={(val) => {
              const nextThickness =
                parseFloat(val.target.value) > 10 ? 10 : parseFloat(val.target.value);

              setDotThickness(nextThickness);
              updateLayerCircleRadius({
                layerName: layerName,
                radius: nextThickness
              });
            }}
          />
        </LayerSettingsRowItem>
      )}
    </LayerSettingsWrapper>
  );

  return (
    <Tooltip title="Settings">
      <Popover
        content={layerSettings}
        placement="bottomLeft"
        trigger="click"
        arrowPointAtCenter={true}>
        <Button type="text" aria-label="Edit">
          <Icon path={mdiCog} size={1} />
        </Button>
      </Popover>
    </Tooltip>
  );
};
