import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { GetApp } from "@material-ui/icons";
import { Popover, Tooltip } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { getPropertiesWithForecast } from "utils/data-table";

import { useUser } from "hooks";

import { useGroupByState } from "components/groupBy/group-by.context";
import { IconSpinner } from "components/icons";

import useBetaFeatures from "../../hooks/useBetaFeatures";
import { EntityKind } from "../../models/entityKind";
import { useTable } from "./context";
import { useExportMutation } from "./hooks";

export interface ISaveButton {
  selectedProperties: string[];
}

const ExportButton = ({ selectedProperties, dataSources }) => {
  const [{ entityKind }] = useTable();
  const [exportDataOpen, setExportDataOpen] = useState(false);
  const filterId = useSelector((state: RootState) =>
    entityKind == EntityKind.Well ? state.filter.filterId : state.facilityFilter.filterId
  );
  const syncWells = useSelector((state: RootState) => state.app.syncWells);
  const { hasTrialAccount, isReadonly } = useUser();
  const { hasFeature } = useBetaFeatures();
  const canExportMidstream =
    entityKind == EntityKind.Facility && hasFeature("Midstream Export");
  const canExport =
    !isReadonly &&
    !hasTrialAccount &&
    (entityKind == EntityKind.Well || canExportMidstream);
  const { selectedForecastFolderName, selectedPdenSource } = useGroupByState();
  const exportFile = (response, fileName) => {
    const encodedUri = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = encodedUri;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportMutation = useExportMutation(
    (response, variables) => {
      const filename =
        variables.format === "Excel" ? "EVA Export.xlsx" : "EVA Export.csv";
      exportFile(response.data, filename);
    },
    (error) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = error as any;
      if (err && err.response?.data) {
        // eslint-disable-next-line no-console
        console.error("Export error", err.response?.data);
      }
      toast.error("Failed to export data");
    }
  );

  return !canExport ? null : (
    <Popover
      overlayClassName="export-popover"
      placement="bottomLeft"
      trigger="click"
      open={exportDataOpen}
      onOpenChange={(visible) => setExportDataOpen(visible)}
      content={
        <div className={`export-container hidden}`}>
          <button
            className={`export-button`}
            disabled={exportMutation.isLoading}
            onClick={() => {
              const properties = getPropertiesWithForecast(
                selectedProperties,
                dataSources
              );
              exportMutation.mutate({
                filterId: filterId,
                properties: properties,
                format: "CSV",
                forecastSource: selectedForecastFolderName,
                pdenSource: selectedPdenSource,
                syncJob: syncWells?.schema,
                entityKind
              });
              setExportDataOpen(false);
            }}>
            <GetApp fontSize="large" />
            <span className="button-text">Export to CSV (.csv)</span>
          </button>
          <button
            className={`export-button`}
            disabled={exportMutation.isLoading}
            onClick={() => {
              const properties = getPropertiesWithForecast(
                selectedProperties,
                dataSources
              );
              exportMutation.mutate({
                filterId: filterId,
                properties: properties,
                format: "Excel",
                forecastSource: selectedForecastFolderName,
                pdenSource: selectedPdenSource,
                syncJob: syncWells?.schema,
                entityKind
              });
              setExportDataOpen(false);
            }}>
            <GetApp fontSize="large" />
            <span className="button-text">Export to Excel (.xlsx)</span>
          </button>
        </div>
      }>
      <Tooltip title="Export Data Table" mouseEnterDelay={0.6}>
        <ActionButton>
          {exportMutation.isLoading ? <IconSpinner /> : <GetApp fontSize="large" />}
          {"Export"}
        </ActionButton>
      </Tooltip>
    </Popover>
  );
};

export default memo(ExportButton);

const ActionButton = styled.button`
  width: 36px;
  height: 36px;
  display: grid;
  grid-template-columns: 36px max-content;
  gap: 6px;
  align-items: center;
  justify-items: center;
  background: transparent;
  border: none;
  color: #a2aaad;
  font-weight: 500;
  padding: 0;
  margin: 0;
  transition: color 0.15s ease-out;
  overflow: hidden;
  pointer-events: all;
  cursor: pointer;

  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;
