import axios from "axios";

const dataServiceEndPoint = process.env.REACT_APP_DATA_ROOT;

export interface IpdbField {
  name: string;
  displayName: string;
  unit: string;
  sumOrAvg: string;
  min: number;
  max: number;
  bin: number;
  tooltip: string;
}

export interface IpdbSourcePlay {
  source: string;
  plays: string[];
}

const fetchPlayTo3DDataSources = async () => {
  const result = await axios.get<string[]>(
    `${dataServiceEndPoint}/api/v1/data/ipdb/playto3d/sources`
  );

  return result.data;
};

const fetchPlayTo3DDataPrivateSources = async () => {
  const result = await axios.get<IpdbSourcePlay[]>(
    `${dataServiceEndPoint}/api/v1/data/ipdb/playto3d/sources/private`
  );

  return result.data;
};

async function fetchRadiusDimensions(uwis) {
  const url = `${dataServiceEndPoint}/api/v1/data/ipdb/xdaDimensions`;
  const response = await axios.post(url, uwis);
  return response.data;
}

async function fetchDimensionsFromPlay(play) {
  const url = `${dataServiceEndPoint}/api/v1/data/ipdb/xdaDimensions/${play}`;
  const response = await axios.get(url);
  return response.data;
}

const fetchPlayTo3DFields = async (source: string) => {
  const result = await axios.get<IpdbField[]>(
    `${dataServiceEndPoint}/api/v1/data/ipdb/playto3d/${source}/fields`
  );

  return result.data;
};

const fetchPlayZoneFields = async (modelSource: string, zones: string[]) => {
  if (!zones || !zones.length) {
    return [];
  }

  const result = await axios.post<IpdbField[]>(
    `${dataServiceEndPoint}/api/v1/data/ipdb/playzone/${encodeURIComponent(
      modelSource
    )}/fields`,
    zones
  );

  return result.data;
};

export {
  fetchPlayTo3DFields,
  fetchPlayZoneFields,
  fetchPlayTo3DDataSources,
  fetchPlayTo3DDataPrivateSources,
  fetchRadiusDimensions,
  fetchDimensionsFromPlay
};
