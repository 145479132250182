import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ORG_FOCUS_FIELDS } from "constants/settings.constants";
import _sortBy from "lodash/sortBy";
import { getCategoryList } from "store/features/groupBy/groupBySlice";
import { RootState } from "store/rootReducer";

import { IGroupByListItem } from "models";
import { EntityKind } from "models/entityKind";

import { useGroupByUpdater } from "../group-by.context";

const sort_key = "sortOrder";

/**
 * Synchronizes the category list in redux-store,
 * with 'categoryList' in GroupBy context
 */
const useCategoryList = (entity: EntityKind): void => {
  const dispatch = useDispatch();
  const updateGroupByState = useGroupByUpdater();

  const syncWells = useSelector((state: RootState) => state.app.syncWells);

  const categoryList = useSelector((state: RootState) => state.groupBy.categoryList);

  const facilityFields = useSelector((state: RootState) => state.groupBy.facilityFields);

  useEffect(() => {
    if (
      (entity === EntityKind.Well &&
        (!categoryList?.length ||
          categoryList.findIndex((item) => item.name == ORG_FOCUS_FIELDS) < 0)) ||
      (entity === EntityKind.Facility && !facilityFields?.length)
      //ensure org focus fields is in the category list
      //if it is not then reload it
      //this can happen when the user pops out a dashboard and resets
      //all the state causing category list to be missing org_focus_fields
    ) {
      dispatch(getCategoryList(entity));
      return;
    }

    if (entity === EntityKind.Well && categoryList.length) {
      const list: IGroupByListItem[] = _sortBy(categoryList, [sort_key]);
      updateGroupByState({ type: "store_list_updated", payload: list });
    }
    if (entity === EntityKind.Facility && facilityFields.length) {
      const list: IGroupByListItem[] = _sortBy(facilityFields, [sort_key]);
      updateGroupByState({ type: "store_list_updated", payload: list });
    }
  }, [categoryList, facilityFields, syncWells?.schema]);
};

export default useCategoryList;
