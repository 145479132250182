import styled from "styled-components";

const HeaderContainer = styled.div``;

const Header = styled.div`
  font-weight: bold;
  padding: 10px 15px 6px 15px;
  font-size: 1.4rem;
  border-bottom: 1px solid #d9e1e2;
`;

const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding: 10px 15px 0px 15px;
  gap: 5px;
`;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ProductHeader({ children, header = "" }): JSX.Element {
  const isLiquidYields = ["Yields Raw", "Yields Sales"].includes(header);
  const isProductYields = ["Yields Inlet", "Yields Outlet"].includes(header);

  return (
    <HeaderContainer>
      <Header>
        {isLiquidYields ? "Liquids Yields" : isProductYields ? "Product Yields" : header}
      </Header>
      <ProductContainer>{children}</ProductContainer>
    </HeaderContainer>
  );
}
