import { useDispatch, useSelector } from "react-redux";

import { setLayers } from "store/features";
import { RootState } from "store/rootReducer";

interface IUpdateLayerCircleRadiusParams {
  layerName: string;
  radius: number;
}

export function useUpdateLayerCircleRadius() {
  const dispatch = useDispatch();
  const mapLayers = useSelector((state: RootState) => state.map.layers);

  const updateLayerCircleRadius = ({
    layerName,
    radius
  }: IUpdateLayerCircleRadiusParams) => {
    const updatedMapLayers = mapLayers.map((layer) => {
      if (layer.id === layerName) return { ...layer, circleRadius: radius };
      return { ...layer };
    });

    dispatch(setLayers(updatedMapLayers));
  };

  return { updateLayerCircleRadius };
}
