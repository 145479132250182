// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useState } from "react";

import { mdiCog } from "@mdi/js";
import { Popover } from "antd";

import XdaSettingsOptions from "./XdaSettingsOptions";
import XdaToolbarButton from "./XdaToolbarButton";

function XdaSettingsToggle() {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={<XdaSettingsOptions />}
      trigger="click"
      placement="bottomRight"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <XdaToolbarButton
        icon={<Icon path={mdiCog} size={1.4} />}
        active={false}
        overflowLabel="Settings"
        tooltipText="XDA Settings"
        onToggle={(v) => setVisible(v)}
      />
    </Popover>
  );
}

export default XdaSettingsToggle;
