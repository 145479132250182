import { ALL_PRODUCT_TYPES } from "../../constants";

export const getProductIcon = (label: string): React.ReactNode | undefined => {
  let productType = Object.values(ALL_PRODUCT_TYPES).find((type) => type.label === label);
  // If no match is found by label, try to find by key
  if (!productType) {
    productType = Object.values(ALL_PRODUCT_TYPES).find((type) => type.key === label);
  }
  return productType ? productType.icon : undefined;
};
