import { useDispatch } from "react-redux";

import { Edit } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Popconfirm } from "antd";
import classnames from "classnames";
import { requestAddNewField } from "store/features";
import styled from "styled-components";

import { useUser } from "hooks";

import { IGroupByListColumn } from "models/groupBy";

import { Tooltip } from "components/base";
import { useGeomBinDispatch } from "components/geom-bin/hooks/useGeomBinDispatch";

import { useGroupByState } from "./group-by.context";
import { useField, useMutationFieldDelete, useMutationGeomBinDelete } from "./hooks";

export type UserFieldActionT = {
  field: IGroupByListColumn;
  visible: boolean;
};

function UserFieldAction({ field, visible }: UserFieldActionT) {
  const dispatch = useDispatch();
  const { id, title, fieldType } = field;
  const { mutate: binDelete } = useMutationGeomBinDelete([id]);
  const { isAtLeastPowerUser } = useUser();

  const { isOrgDefinedColumn, isUserDefinedColumn } = useField(field);
  const { props } = useGroupByState();

  const { isLoading, mutate: fieldDelete } = useMutationFieldDelete(
    [id],
    isOrgDefinedColumn
  );

  const geomBinDispatch = useGeomBinDispatch();

  // derived state
  const isOrgAdmin = isAtLeastPowerUser && isOrgDefinedColumn;
  const isActionAllowed = (isOrgAdmin || isUserDefinedColumn) && props?.editable;

  const isGeom = fieldType === "geom";

  // do not render if not Org or User field
  if (!isActionAllowed) return null;

  function handleConfirmClick(e) {
    if (isGeom) {
      binDelete();
    } else {
      fieldDelete();
    }
    e.stopPropagation();
    e.preventDefault();
  }

  function handleCancelClick(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  const handleClick = () => {
    if (field.fieldType === "geom") {
      geomBinDispatch({
        payload: {
          isActive: true,
          selectedGeomBinId: field.id,
          isOrg: field.property.startsWith("Org")
        }
      });
    } else {
      const editType = field.pythonScript ? "Calculated" : "Excel";
      const isOrg = field.property.startsWith("Org");

      dispatch(requestAddNewField({ type: editType, isOrg, isEdit: true, field }));
    }
  };

  const rootClassNames = classnames({ visible: isLoading || visible });

  const EditButton = (
    <Tooltip title="Edit Field">
      <ActionButton
        shape="circle"
        loading={isLoading}
        onClick={handleClick}
        icon={<Edit />}
      />
    </Tooltip>
  );

  const DeleteButton = (
    <Popconfirm
      onConfirm={handleConfirmClick}
      onCancel={handleCancelClick}
      title={`Please confirm you wish to delete ${isGeom ? "bin" : "field"} ${title}.`}>
      <Tooltip title="Delete Field">
        <ActionButton
          shape="circle"
          danger
          loading={isLoading}
          onClick={(evt) => evt.stopPropagation()}
          icon={<DeleteIcon />}
        />
      </Tooltip>
    </Popconfirm>
  );

  return (
    <Wrapper className={rootClassNames}>
      {EditButton}
      {DeleteButton}
    </Wrapper>
  );
}

export default UserFieldAction;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  visibility: hidden;

  &.visible {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }

  .ant-btn {
    min-width: 28px;
    width: 28px;
    height: 28px;
    align-items: center;
    border: none;
    box-shadow: none;
  }
`;

const ActionButton = styled(Button)`
  color: ${() => "#a2aaad"};
  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;
