import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { uid } from "utils";

import { getWellsFromFilterId } from "api/filter";

import { Screenshot, useChartDispatch, useChartState } from "components/chart";
import { useScreenshotContext } from "components/screenshot/hooks";
import { useUserSettings } from "components/user/hooks";

import FourProductRateTime from "./FourProductRateTime";
import InjectionWellPairs from "./InjectionWellPairs";
import Stats from "./Stats";
import TypeWell from "./TypeWell";
import WaterBalance from "./WaterBalance";
import { ScreenshotToggle } from "./components";

const SamCharts = ({ id }) => {
  const { screenshot } = useChartState();
  const { settings } = useScreenshotContext();

  const chartDispatch = useChartDispatch();
  const { userAbbreviations } = useUserSettings();
  const abbreviations = userAbbreviations?.abbreviations ?? [];

  const chartRef = useRef<HTMLElement>(null);

  const filterId = useSelector((state: RootState) => state.filter.filterId);

  const { data: wellList, refetch: updateWellList } = useQuery(
    "well-list-query",
    async () => {
      const response = await getWellsFromFilterId(filterId);
      if (response.status === 200) {
        return response.data.uwiList?.map((w) => w.formattedId).sort();
      }
      return [];
    }
  );

  useEffect(() => {
    updateWellList();
  }, [filterId, updateWellList]);

  // sync chart ID with context
  useEffect(() => {
    chartDispatch({ type: "id", payload: id });
  }, [id, chartDispatch]);

  return (
    <RootContainer screenshot={screenshot?.visible}>
      <ActionWrapper>
        <ScreenshotToggle widgetComponentType="sam" />
      </ActionWrapper>
      <Screenshot key="screenshot" containerId="sam-chart-screenshot-overlay" />
      <ChartContainer
        ref={chartRef}
        width={settings?.width}
        height={settings?.height}
        screenshotVisible={screenshot?.visible}
        className="ChartPlot">
        {/* Screenshot component will be rendered here using Portal */}
        <ScreenshotContainer id={"sam-chart-screenshot-overlay"} />
        <Stats wellList={wellList} setting={settings} abbreviations={abbreviations} />
        <Container screenshot={screenshot?.visible}>
          <FourProductRateTime
            id={uid()}
            wellList={wellList}
            setting={settings}
            abbreviations={abbreviations}
          />
          <InjectionWellPairs
            id={uid()}
            wellList={wellList}
            setting={settings}
            abbreviations={abbreviations}
          />
          <TypeWell
            id={uid()}
            setting={settings}
            wellList={wellList}
            abbreviations={abbreviations}
          />
          <WaterBalance
            id={uid()}
            wellList={wellList}
            setting={settings}
            abbreviations={abbreviations}
          />
        </Container>
      </ChartContainer>
    </RootContainer>
  );
};

export default SamCharts;

const RootContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.screenshot ? "rgba(46, 72, 88, 0.24)" : "inherit"};
  align-items: center;
  flex-direction: column;
  .popup-icons {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    svg {
      flex-shrink: 0;
    }
  }
  .Screenshot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .overflow-name {
    display: none;
  }
  .popup-icons > * + *,
  .toggle-icons > * + * {
  }
  .icon-toggle {
    color: #a2aaad;
    transition: color var(--duration);
    padding: 0;
    &:hover,
    &.isActive {
      color: var(--color-primary);
    }
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
    transition-delay: 0s;
    visibility: visible;
  }
`;

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
  background-color: ${(props) => (props.screenshot ? "#fff" : "inherit")};
`;

const ChartContainer = styled.div`
  display: grid;
  width: ${(props) =>
    props.screenshotVisible && props.width ? props.width + "px" : "100%"};
  height: ${(props) =>
    props.screenshotVisible && props.height ? props.height + "px" : "100%"};
  background-color: ${(props) => (props.screenshotVisible ? "#fff" : "inherit")};
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
`;

const ScreenshotContainer = styled.div`
  position: absolute;
  display: grid;
  justify-content: center;
  pointer-events: none;
`;
