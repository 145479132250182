import { Annotation } from "models/chart";

import { MinMaxT } from "../ChartWrapper";
import { BaseChart } from "./baseChart";

export class RateDate extends BaseChart {
  getDefaultAxisMinMax(): MinMaxT {
    return {
      xMin: null,
      xMax: "2050",
      yMin: null,
      yMax: null
    };
  }

  getDefaultAxis(sessionMinMax?: MinMaxT): MinMaxT {
    const defaultMinMax = this.getDefaultAxisMinMax();
    if (!sessionMinMax) {
      return defaultMinMax;
    }
    const yMax = !isNaN(parseFloat(sessionMinMax?.yMax?.toString()))
      ? parseFloat(sessionMinMax?.yMax?.toString())
      : defaultMinMax.yMax;
    const yMin = !isNaN(parseFloat(sessionMinMax?.yMin?.toString()))
      ? parseFloat(sessionMinMax?.yMin?.toString())
      : defaultMinMax.yMin;
    const xMax = sessionMinMax?.xMax ?? defaultMinMax.xMax;
    const xMin = sessionMinMax?.xMin ?? defaultMinMax.xMin;
    return {
      xMin,
      xMax,
      yMin,
      yMax
    };
  }

  getMarkLineData(annotations: Annotation[], yMin: number) {
    const marklineData = annotations.map((item, index) => {
      return [
        {
          xAxis: item.xValue,
          yAxis: yMin,
          label: {
            show: true,
            formatter: () => item.comment
          }
        },
        {
          xAxis: item.xValue,
          y: `${100 + 25 * index}`
        }
      ];
    });
    return {
      lineStyle: {
        type: "dashed"
      },
      symbol: [],
      data: marklineData
    };
  }
}
