import { useMutation, useQueryClient } from "react-query";

import axios from "axios";

import { projectServiceEndpoint } from "api/project";

import { PROJECT_LAYER_LIST_QUERY_KEYS } from "components/project/layers/queries";
import {
  ORGANIZATION_SHAPEFILES_QUERY_KEYS,
  PROJECT_SHAPEFILES_QUERY_KEYS
} from "components/project/shapefiles/queries";

interface IUseDeleteProjectMutationParams {
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
  projectId: string;
}

interface IDeleteProjectShapefileFolderParams {
  shapefileNodeId: string;
}

export async function deleteProjectShapefileFolder(
  params: IDeleteProjectShapefileFolderParams
) {
  return await axios.delete(
    `${projectServiceEndpoint}/shapefile-node/${params.shapefileNodeId}`
  );
}

export function useDeleteProjectShapefileFolderMutation({
  onError,
  onMutate,
  onSettled,
  onSuccess,
  projectId
}: IUseDeleteProjectMutationParams) {
  const queryClient = useQueryClient();

  return useMutation(
    (params: IDeleteProjectShapefileFolderParams) => deleteProjectShapefileFolder(params),
    {
      onMutate: onMutate && onMutate,
      onSuccess: (data) => {
        if (projectId === "organization") {
          queryClient.invalidateQueries({
            queryKey: [ORGANIZATION_SHAPEFILES_QUERY_KEYS.organizationShapefiles]
          });
          queryClient.invalidateQueries({
            queryKey: [PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles]
          });

          queryClient.invalidateQueries({
            queryKey: [PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList]
          });
        }

        queryClient.invalidateQueries({
          queryKey: [PROJECT_SHAPEFILES_QUERY_KEYS.currentProjectShapefiles, projectId]
        });

        queryClient.invalidateQueries({
          queryKey: [PROJECT_LAYER_LIST_QUERY_KEYS.currentProjectLayerList, projectId]
        });

        onSuccess && onSuccess(data);
      },
      onError: (error) => {
        onError && onError(error);
      },
      onSettled: onSettled && onSettled
    }
  );
}
