// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { GetApp } from "@material-ui/icons";
import { mdiDownload } from "@mdi/js";
import { Popover } from "antd";
import { RootState } from "store/rootReducer";
import styled from "styled-components";

import { useUser, useUserSettingFetcher } from "hooks";

import { ExportForecastRequest, TypeWellId, exportForecasts } from "api/userForecasts";

import { Tooltip } from "components/base";
import { IconSpinner } from "components/icons";
import { FORECAST_EXPORT_SETTING } from "components/user-settings/constants/userSetting.constants";
import { ForecastExportSetting } from "components/user-settings/models/ForecastExportSetting";

const exportFile = (response) => {
  const contentDisposition = response?.headers["content-disposition"];
  // Make sure content-disposition exists and that the filename
  // contains 'McDanielForecasts' which is the naming standard produced from the backend
  if (response?.data && contentDisposition) {
    const parts = contentDisposition.split("filename*=UTF-8''");
    if (parts.length >= 2) {
      const fileName = parts[1];
      const encodedUri = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = encodedUri;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }
  }
  toast.error("Failed to export forecast");
};

function ExportButton({ onExport, exporting, children }) {
  return (
    <button
      className={`export-button`}
      disabled={exporting}
      onClick={async (evt) => {
        await onExport();
        evt.stopPropagation();
        evt.preventDefault();
      }}>
      {exporting ? (
        <>
          <IconSpinner />
          <span className="button-text">Exporting...</span>
        </>
      ) : (
        children
      )}
    </button>
  );
}

export enum WellSource {
  McDanielResearch = "McDResearchForecasts",
  TypeWells = "TypeWells"
}

export interface ExportForecastsModel {
  wellSource: WellSource;
  node?;
  type?: "folder" | "forecast" | "selected";
}

function ExportForecastsButton({ wellSource, node, type }: ExportForecastsModel) {
  const checkedGlobalTypeWells = useSelector(
    (state: RootState) => state.arps.checkedGlobalTypeWells
  );
  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);
  const filterId = useSelector((state: RootState) => state.filter.filterId);

  const { hasTrialAccount, isReadonly } = useUser();

  const [error, setError] = useState(null);

  const { data: exportForecastPreference, refetch } =
    useUserSettingFetcher<ForecastExportSetting>(FORECAST_EXPORT_SETTING);

  const enableValNav = exportForecastPreference?.enableValNav ?? true;
  const enableMosaic = exportForecastPreference?.enableMosaic ?? true;

  const canExport = !isReadonly && !hasTrialAccount;

  const csvExportValNavMutation = useMutation(
    async (input: ExportForecastRequest) =>
      await exportForecasts({ ...input, format: "CSV" }),
    {
      onSuccess: (data) => {
        setError(null);
        exportFile(data);
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        if (err && err.response?.data) {
          // eslint-disable-next-line no-console
          setError(`Failed to export: ${err.message}`);
        }
      }
    }
  );

  const xmlExportValNavMutation = useMutation(
    async (input: ExportForecastRequest) =>
      await exportForecasts({ ...input, format: "XML" }),
    {
      onSuccess: (data) => {
        setError(null);
        exportFile(data);
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        if (err && err.response?.data) {
          // eslint-disable-next-line no-console
          setError(`Failed to export: ${err.message}`);
        }
      }
    }
  );

  const csvExportMosaicMutation = useMutation(
    async (input: ExportForecastRequest) =>
      await exportForecasts({ ...input, format: "CSV" }),
    {
      onSuccess: (data) => {
        setError(null);
        exportFile(data);
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as any;
        if (err && err.response?.data) {
          // eslint-disable-next-line no-console
          setError(`Failed to export: ${err.message}`);
        }
      }
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (
    !canExport ||
    (exportForecastPreference &&
      !exportForecastPreference.enableMosaic &&
      !exportForecastPreference.enableValNav)
  ) {
    return null;
  }

  function getTypeWells(): TypeWellId[] {
    const getAllTypeWellsInFolder = (node): TypeWellId[] => {
      if (!node) return [];

      let typeWells: TypeWellId[] = [];

      if (node.type !== "folder") {
        typeWells.push({
          id: node.id,
          name: node.title,
          folderId: node.folderId
        });
      }

      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          typeWells = typeWells.concat(getAllTypeWellsInFolder(child));
        });
      }

      return typeWells;
    };

    switch (type) {
      case "selected":
        return selectedTypeWell
          ? [
              {
                id: selectedTypeWell.id,
                name: selectedTypeWell.title,
                folderId: selectedTypeWell.folderId
              }
            ]
          : [];
      case "folder":
        return node ? getAllTypeWellsInFolder(node) : [];
      case "forecast":
        return node
          ? [
              {
                id: node.id,
                name: node.title,
                folderId: node.folderId
              }
            ]
          : [];
      default:
        return wellSource == WellSource.TypeWells
          ? checkedGlobalTypeWells.map((tw) => ({
              id: tw.id,
              name: tw.title,
              folderId: tw.folderId
            }))
          : [];
    }
  }

  return (
    <RootContainer>
      <Popover
        overlayClassName="export-popover"
        placement="right"
        trigger="click"
        content={
          <PopoverContentWrapper>
            {enableValNav && enableMosaic && <GroupLabel>ValNav</GroupLabel>}
            {enableValNav && (
              <ExportButton
                onExport={() => {
                  const data: ExportForecastRequest = {
                    filterId: filterId,
                    typeWellIds: getTypeWells(),
                    settings: {
                      source: wellSource.toString(),
                      thirdPartyFormat: "ValNav"
                    }
                  };

                  csvExportValNavMutation.mutate(data);
                }}
                exporting={csvExportValNavMutation.isLoading}>
                <GetApp fontSize="large" />
                <span className="button-text">Export to CSV (.csv)</span>
              </ExportButton>
            )}
            {enableValNav && (
              <ExportButton
                onExport={() => {
                  const data: ExportForecastRequest = {
                    filterId: filterId,
                    typeWellIds: getTypeWells(),
                    settings: {
                      source: wellSource.toString(),
                      thirdPartyFormat: "ValNav"
                    }
                  };
                  xmlExportValNavMutation.mutate(data);
                }}
                exporting={xmlExportValNavMutation.isLoading}>
                <GetApp fontSize="large" />
                <span className="button-text">Export to XML (.xml)</span>
              </ExportButton>
            )}
            {enableValNav && enableMosaic && <GroupLabel>Mosaic</GroupLabel>}
            {enableMosaic && (
              <ExportButton
                onExport={() => {
                  const data: ExportForecastRequest = {
                    filterId: filterId,
                    typeWellIds: getTypeWells(),
                    settings: {
                      source: wellSource.toString(),
                      thirdPartyFormat: "Mosaic"
                    }
                  };
                  csvExportMosaicMutation.mutate(data);
                }}
                exporting={csvExportMosaicMutation.isLoading}>
                <GetApp fontSize="large" />
                <span className="button-text">Export to CSV (.csv)</span>
              </ExportButton>
            )}
            {error && <ErrorComponent>{error}</ErrorComponent>}
          </PopoverContentWrapper>
        }>
        {wellSource == WellSource.McDanielResearch ? (
          <Tooltip
            placement="top"
            title={
              wellSource == WellSource.McDanielResearch
                ? "Export Forecasts"
                : "Export Type Wells"
            }>
            <ExportForecastActionButton className="export-action-button">
              <Icon path={mdiDownload} size={1.4} />
            </ExportForecastActionButton>
          </Tooltip>
        ) : (
          <TypeWellAction
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
            }}>
            <Icon path={mdiDownload} size={1.0} />
            <span>Download</span>
          </TypeWellAction>
        )}
      </Popover>
    </RootContainer>
  );
}

export default ExportForecastsButton;

const RootContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TypeWellAction = styled.div`
  height: 3.2rem;
  display: flex;
  align-items: center;
  gap: var(--space-2);
  color: var(--color-text-50);
  font-weight: 500;
  padding: 0 var(--space-3);
  cursor: pointer;
  &:hover {
    color: ${(props) => (props.danger ? "var(--color-danger)" : "var(--color-primary)")};
  }
`;

const ExportForecastActionButton = styled.button`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  margin: 3px;
  cursor: pointer;
  background: transparent;
  color: ${(props) => (props.disabled ? "#ddd" : "#a2aaad")};

  &:hover {
    color: ${(props) =>
      props.disabled
        ? "#ddd"
        : props.danger
        ? "var(--color-danger)"
        : "var(--color-primary)"};
  }

  &[disabled]:hover > svg {
    cursor: default;
  }
`;

const GroupLabel = styled.div`
  margin-top: 5px;
  margin-left: 8px;
  font-size: 12px;
  color: #a2aaad;
`;

const PopoverContentWrapper = styled.div`
  ${GroupLabel}:first-child {
    padding-top: 5px;
  }
`;

const ErrorComponent = styled.div`
  color: red;
  width: 100%;
  max-width: fit-content;
`;
