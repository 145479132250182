import { useMemo } from "react";
import { useSelector } from "react-redux";

import { TYPE_WELLS } from "constants/settings.constants";
import { RootState } from "store/rootReducer";

export const useTreeData = (type: string) => {
  const filteredForecastFolders = useSelector(
    (state: RootState) =>
      state.arps[
        type === TYPE_WELLS ? "filteredTypeWellFolders" : "filteredForecastFolders"
      ]
  );

  const transformData = (data) => {
    const map = {};
    const treeData = [];
    data.forEach((item) => {
      map[item.folderId] = {
        ...item,
        key: item.folderId,
        children: item.children ? [...item.children] : [],
        type: "folder",
        isFolder: true
      };
    });
    data.forEach((item) => {
      if (item.parentId && item.parentId !== "") {
        if (!map[item.parentId]) {
          map[item.parentId] = { children: [] };
        }
        map[item.parentId].children.push(map[item.folderId]);
        map[item.parentId].children.sort((a, b) => a.order - b.order);
      } else {
        treeData.push(map[item.folderId]);
      }
    });
    return treeData;
  };
  const treeData = useMemo(() => {
    return type === TYPE_WELLS
      ? transformData(filteredForecastFolders)
      : filteredForecastFolders;
  }, [type, filteredForecastFolders]);

  return {
    treeData
  };
};
